.article {
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
   background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
}

.tuneitcon-title{
    text-align: center;
    color: black;
    font-size: 30px;
}

.page-title{
    text-align: left;
    color: black;
    font-size: 20px;
    margin-left: 150px;
    margin-bottom: 0px;
}
.para-title {
    text-align: left;
    color: black;
    font-size: 15px;
    margin-left: 150px;
    margin-bottom: 0px;
}

.sub-para-title-1 {
    text-align: left;
    color: black;
    font-size: 15px;
    margin-left: 0px;
    margin-bottom: 0px;
}

.para-gen-content{
    text-align: left;
    color: black;
    display: block;
    font-family:  'Times New Roman', Times, serif;
    font-size: 16px;
    font-weight: 400;
    /*height: 66px;*/
    margin-block-end: 10px;
    margin-block-start: 0px;
    margin-bottom: 10px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-left: 150px;
    margin-right: 0px;
    margin-top: 0px;
    width: 600px;

}

.headline-indicator-image{
    maxWidth: 160;
    margin-left: 150px;
    align: left;
}

.email-link{
    color: black;
}