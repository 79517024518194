@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

/*https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap" rel="stylesheet*/

.MainNavigator {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    position: relative;
    align-items: center;
 /*   min-height: 10vh;*/
    background-color: rgb(73, 79, 82);
    color: white;
    border-radius: 8px;
    width: 752px;
    height: 80px;
  }

  .menu {
    background-color: white;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 752px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    position: relative;
}
  
  .MainNavigator-links{
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
  }

  .MainNavigator-link-items{
      color: whitesmoke;
  }

  .MainNavLink {
    height: 100%;
    margin-right: 20px;
}

.menu__list-item {
    display: inline-block;
    height: 100%;
    margin-right: 20px;
    padding: 10px 10px;
}

.menu__list-item:hover {
    color: #ffaa3b;
}
/*.menu__list-item:active {
    color: #ffaa3b;
    border-top: 4px solid #ffaa3b;
    font-weight: 700;
}*/


.menu__list-item--active {
    color: #ffaa3b;
    border-top: 4px solid #ffaa3b;
    font-weight: 700;
}

.menu__link {
    color: #3a3e47;
    display: inline-block;
    height: 100%;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 3px;
}

  .MainNavLinkItem {
    /*display: inline-block;*/
    color: #3a3e47;
    height: 100%;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 3px;
}


* {
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

html, body {
    margin: 0;
    padding: 0;
}



.container {
    width: 1000px;
    margin: 20px auto;
}

.center {
    display: flex;
    justify-content: center;
}

.hide {
    display: none;
}



.menu__right {
    display: flex;
    align-items: center;
    height: 100%;
}

.menu__list {
    list-style-type: none;
    padding: 0;
    height: 100%;
    margin: 0;
    margin-right: 40px;
}



.menu__list-item:last-child {
    margin-right: 0;
}



.menu__link:hover {
    color: #ffaa3b;
}

.menu__link--active {
    color: #ffaa3b;
    border-top: 4px solid #ffaa3b;
    font-weight: 700;
}

.menu__logo {
    text-indent: -9999999px;
    display: inline-block;
    height: 100%;
    width: 92px;
    background-size: cover 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
}


.menu__logo1 {
    font-family: 'Dancing Script', cursive;
}
/*font-family: 'Dancing Script', cursive;*/